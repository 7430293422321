<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form ref="form" :model="formData" label-width="80px" @submit.native.prevent>
                <el-row>
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">{{this.$store.getters.getRunTimeTest ?'(测试环境,数据定期删除)':''}}当前：进仓单</strong>
                                    <el-select style="margin-left: 8px;width: 80px" v-model="warehouseBackOrGo.name" no-data-text="没有搜索结果!" placeholder="全部"
                                               default-first-option size="mini"
                                               @change="warehouseBackOrGo.id = $event.id,warehouseBackOrGo.name = $event.name"
                                               @focus="$_searcWarehouseList" clearable filterable>
                                        <el-option v-for="item in this.$store.getters.getWarehouseList" :key="item.id" :label="item.name"
                                                   :value="item">
                                            <span style="margin:15px;">{{ item.name}}</span>
                                        </el-option>
                                    </el-select>
                                    <el-button-group style="margin-left: 8px">
                                        <el-button size="mini" type="success" title="上一页" @click="$_BackOrGo(formData.id,formData.bill_id_type,warehouseBackOrGo,'Back',false,null)" icon="el-icon-caret-left"></el-button>
                                        <el-button size="mini" type="success" title="下一页" @click="$_BackOrGo(formData.id,formData.bill_id_type,warehouseBackOrGo,'Go',false,null)" icon="el-icon-caret-right"></el-button>
                                    </el-button-group>
                                    <strong class="pull-right" style=" font-size: 16px;color: red ">{{formData.is_cancel?'已作废':''}}{{titleList}}</strong>
                                </el-col>
                            </el-row>
                        </div>
                        <el-row>
                            <el-col :lg="4" :sm="12" :md="8">
                                <el-tooltip class="item" effect="dark"
                                            placement="bottom-end" :open-delay = "750">
                                    <div slot="content">
                                        单号唯一ID:{{formData.id ? formData.id : '自动生成'}}<br>
                                        修改人:{{formData.last_name_warehouse_bill ? formData.last_name_warehouse_bill : ''}}<br>
                                        修改时间:{{formData.last_date_warehouse_bill ? formData.last_date_warehouse_bill : ''}}<br>
                                        创建时间:{{formData.creat_date_warehouse_bill ? formData.creat_date_warehouse_bill : ''}}
                                    </div>
                                    <el-form-item label="单号:">
                                        <el-input v-model="formData.bill_id" :readonly="true" placeholder="自动生成"
                                                  @keyup.native.enter="$_focusNext('only_bill_date')" size="mini"></el-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="日期:">
                                    <el-date-picker
                                            ref="only_bill_date"
                                            v-model="formData.only_bill_date"
                                            align="right"
                                            type="date"
                                            size="mini"
                                            :clearable="false"
                                            :picker-options="pickerOptions"
                                            style="width:140px"
                                            @change="$_focusNext('supplier')"
                                            placeholder="选择日期">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="5" :sm="12" :md="8" v-if="formData.bill_type == '外购进仓'">
                                <el-tooltip class="item" effect="dark" placement="top" :open-delay = "750">
                                    <div slot="content">
                                        UUID:  {{formData.company_uuid}}<br/>
                                    </div>
                                    <el-form-item label="供应商:" label-width="100px">
                                        <el-select ref="supplier" v-model="formData.supplier" default-first-option remote
                                                   style="width: 100%"
                                                   :remote-method="$_searchSupplierList" clearable filterable size="mini"
                                                   placeholder="可搜索查询"
                                                   @keyup.native.enter="$_blurNext('supplier'),$_focusNext('handled_by')">
                                            <el-option
                                                    v-for="item in $store.getters.getSupplierList"
                                                    :key="item.id"
                                                    :label="item.supplier_name"
                                                    :value="item.supplier_name">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="经手人:" label-width="100px">
                                    <el-select ref="handled_by" v-model="formData.handled_by" clearable
                                               placeholder="可搜索查询"
                                               size="mini"
                                               default-first-option
                                               :filter-method="$_handledByListFilter"
                                               @keyup.native.enter="$_blurNext('handled_by'),$_focusNext('tax_tate_warehouse_bill')"
                                               filterable>
                                        <el-option
                                                v-for="item in $store.getters.getHandledByList"
                                                :key="item.nick_name"
                                                :label="item.nick_name"
                                                :value="item.nick_name">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-tooltip class="item" effect="dark" placement="top" :open-delay = "750">
                                    <div slot="content">
                                        产品税率<br/>
                                    </div>
                                    <el-form-item label="税率:">
                                        <vxe-input ref="tax_tate_warehouse_bill" v-model="formData.tax_tate_warehouse_bill"
                                                   placeholder="请输入" type="number" size="mini"
                                                   @change="$_setTaxTateStockRow(),$_singleCountEventEvery(),isDataChanage = true"
                                                   @keyup.native.enter="$_focusNext('remark_warehouse_bill')">
                                            <template #suffix>
                                                <span>%</span>
                                            </template>
                                        </vxe-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                            <el-col :lg="4" :sm="12" :md="8">
                                <el-form-item label="备注 :">
                                    <el-input ref="remark_warehouse_bill" style="width: 170px" v-model="formData.remark_warehouse_bill"
                                              placeholder="请输入" size="mini"
                                              @keyup.native.enter="$_focusNext('bill_type')"></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="进仓类型:">
                                    <el-select ref="bill_type" v-model="formData.bill_type" @change="selectBillType"
                                               default-first-option remote :remote-method="searchBillTypeList"
                                               filterable size="mini" @keyup.native.enter="$_blurNext('bill_type'),$_focusNext('cost_of_the_unit')">
                                        <el-option
                                                v-for="item in billTypeList"
                                                :key="item.id"
                                                :label="item.name"
                                                :value="item.name">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>

                            <el-col :lg="5" :sm="12" :md="8" v-if="formData.bill_type == '外购进仓'">
                                <el-form-item label="外省运费单位:" label-width="110px">
                                    <el-select ref="cost_of_the_unit" v-model="formData.cost_of_the_unit" default-first-option
                                               remote :remote-method="$_searchSupplierList" clearable filterable
                                               size="mini" placeholder="可搜索查询"
                                               @keyup.native.enter="$_blurNext('cost_of_the_unit'),$_focusNext('other_freight_fees_diy')">
                                        <el-option
                                                v-for="item in $store.getters.getSupplierList"
                                                :key="item.id"
                                                :label="item.supplier_name"
                                                :value="item.supplier_name">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>

                            <el-col :lg="3" :sm="12" :md="8" v-if="formData.bill_type == '外购进仓'">
                                <el-tooltip class="item" effect="dark" placement="top" :open-delay = "750">
                                    <div slot="content">
                                        (含税)外省运费
                                    </div>
                                    <el-form-item label="外省运费:" label-width="80px">
                                        <vxe-input style="width: 100px;" :controls="false" :precision="2"
                                                   size="mini" clearable
                                                   type="float" ref="other_freight_fees_diy"
                                                   @change="formData.other_freight_fees_diy = false,$_setNakedOtherFreightFeesTaxMoney(),isDataChanage = true,$_sumAllFees(),$_setOtherTaxMoney(),$_getFeesEveryRow(),$_singleCountEventEvery()"
                                                   @keyup.native.enter="$_focusNext('other_tax')"
                                                   v-model="formData.other_freight_fees"></vxe-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>

                            <el-col :lg="3" :sm="12" :md="8" v-if="formData.bill_type == '外购进仓'">
                                <el-form-item label="外省运费税率:" label-width="100px" >
                                    <vxe-input ref="other_tax" style="width: 90px"
                                               v-model="formData.other_freight_fees_tax"
                                               type="number" size="mini"
                                               @change="$_setNakedOtherFreightFeesTaxMoney(),$_singleCountEventEvery(),isDataChanage = true"
                                               @keyup.native.enter="$_focusNext('pounds_fees')">
                                        <template #suffix>
                                            <span>%</span>
                                        </template>
                                    </vxe-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="4" :sm="12" :md="8" v-if="formData.bill_type == '外购进仓'">
                                <el-tooltip class="item" effect="dark" placement="top" :open-delay = "750">
                                    <div slot="content">
                                        (不含税)外省运费裸价 = 外省运费 ÷ (1 + 外省运费税金)
                                    </div>
                                    <el-form-item label="外省运费裸价:" label-width="110px">
                                        <vxe-input style="width: 110px;" size="mini" :readonly="true"
                                                   :value="this.$XEUtils.commafy(formData.naked_other_freight_fees_tax_money, { digits: 2})"></vxe-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8" v-if="formData.bill_type == '外购进仓'">
                                <el-tooltip class="item" effect="dark" placement="top" :open-delay = "750">
                                    <div slot="content">
                                        外省运费税金 = 外省运费 - 外省运费裸价
                                    </div>
                                    <el-form-item label="外省运费税金:" label-width="110px">
                                        <vxe-input :readonly="true" style="width: 90px;" size="mini"
                                                   :value="this.$XEUtils.commafy(formData.other_freight_fees_tax_money, { digits: 2})"></vxe-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>

                            <el-col :lg="3" :sm="12" :md="8">
                                <el-tooltip class="item" effect="dark" placement="top" :open-delay = "750">
                                    <div slot="content">
                                        修改人:{{formData.last_name_warehouse_bill ? formData.last_name_warehouse_bill : ''}}<br>
                                        修改时间:{{formData.last_date_warehouse_bill ? formData.last_date_warehouse_bill :
                                        ''}}<br>
                                        创建时间:{{formData.creat_date_warehouse_bill ? formData.creat_date_warehouse_bill : ''}}
                                    </div>
                                    <el-form-item label="记录员:">
                                        <el-input ref="registrar" v-model="formData.registrar" :readonly="true"
                                                  size="mini"
                                                  @keyup.native.enter="$_focusNext('last_date_warehouse_bill')"></el-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>

                            <el-col :lg="4" :sm="12" :md="8" v-show="false">
                                <el-form-item label="修改时间:">
                                    <el-input ref="last_date_warehouse_bill" v-model="formData.last_date_warehouse_bill"
                                              placeholder="自动生成" :readonly="true" size="mini"
                                              @keyup.native.enter="$_focusNext('last_name_warehouse_bill')"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8" v-show="false">
                                <el-form-item label="修改人:">
                                    <el-input ref="last_name_warehouse_bill" v-model="formData.last_name_warehouse_bill"
                                              placeholder="自动生成" :readonly="true" size="mini"
                                              @keyup.native.enter="$_focusNext('bill_type')"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row>

                            <el-col :sm="12" :md="2" v-if="formData.bill_type == '外购进仓'">
                                <el-tooltip class="item" effect="dark" placement="top" :open-delay = "750">
                                    <div slot="content">
                                        (不含税)磅费
                                    </div>
                                    <el-form-item label="磅费:">
                                        <vxe-input
                                                style="width: 60px;"
                                                :controls="false"
                                                :precision="2"
                                                size="mini" clearable
                                                type="float" ref="pounds_fees" v-model="formData.pounds_fees"
                                                @change="formData.pounds_fees_diy = false,isDataChanage = true,$_sumAllFees(),$_setOtherTaxMoney(),$_getFeesEveryRow(),$_singleCountEventEvery()"
                                                @keyup.native.enter="$_focusNext('wind_cut_fees')">
                                        </vxe-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>

                            <el-col :sm="12" :md="2" v-if="formData.bill_type == '外购进仓'">
                                <el-tooltip class="item" effect="dark" placement="top" :open-delay = "750">
                                    <div slot="content">
                                        (不含税)风割费
                                    </div>
                                    <el-form-item label="风割费:">
                                        <vxe-input style="width: 60px;" :controls="false" :precision="2"
                                                         size="mini" clearable
                                                         type="float" ref="wind_cut_fees" v-model="formData.wind_cut_fees"
                                                         @change="formData.wind_cut_fees_diy = false,isDataChanage = true,$_sumAllFees(),$_setOtherTaxMoney(),$_getFeesEveryRow(),$_singleCountEventEvery()"
                                                         @keyup.native.enter="$_focusNext('loading_car_fees')"></vxe-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>

                            <el-col :sm="12" :md="2" v-if="formData.bill_type == '外购进仓'">
                                <el-tooltip class="item" effect="dark" placement="top" :open-delay = "750">
                                    <div slot="content">
                                        (不含税)装车费
                                    </div>
                                    <el-form-item label="装车费:">
                                        <vxe-input style="width: 60px;" :controls="false" :precision="2"
                                                         size="mini" clearable
                                                         type="float" ref="loading_car_fees"
                                                         v-model="formData.loading_car_fees"
                                                         @change="formData.loading_car_fees_diy = false,isDataChanage = true,$_sumAllFees(),$_setOtherTaxMoney(),$_getFeesEveryRow(),$_singleCountEventEvery()"
                                                         @keyup.native.enter="$_focusNext('transfer_fees')"></vxe-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>

                            <el-col :sm="12" :md="2" v-if="formData.bill_type == '外购进仓'">
                                <el-tooltip class="item" effect="dark" placement="top" :open-delay = "750">
                                    <div slot="content">
                                        (不含税)中转费
                                    </div>
                                    <el-form-item label="中转费:">
                                        <vxe-input style="width: 60px;" :controls="false" :precision="2"
                                                         size="mini" clearable
                                                         type="float" ref="transfer_fees"
                                                         v-model="formData.transfer_fees"
                                                         @change="formData.transfer_fees_diy = false,isDataChanage = true,$_sumAllFees(),$_setOtherTaxMoney(),$_getFeesEveryRow(),$_singleCountEventEvery()"
                                                         @keyup.native.enter="$_focusNext('freight_fees')"></vxe-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                            <el-col :sm="12" :md="2" v-if="formData.bill_type == '外购进仓'">
                                <el-tooltip class="item" effect="dark" placement="top" :open-delay = "750">
                                    <div slot="content">
                                        (不含税)运费
                                    </div>
                                    <el-form-item label="运费:">
                                        <vxe-input style="width: 60px;" :controls="false" :precision="2"
                                                         size="mini" clearable
                                                         type="float" ref="freight_fees" v-model="formData.freight_fees"
                                                         @change="formData.freight_fees_diy = false,isDataChanage = true,$_sumAllFees(),$_setOtherTaxMoney(),$_getFeesEveryRow(),$_singleCountEventEvery()"
                                                         @keyup.native.enter="$_focusNext('procedure_fees')"></vxe-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                            <el-col :sm="12" :md="2" v-if="formData.bill_type == '外购进仓'">
                                <el-tooltip class="item" effect="dark" placement="top" :open-delay = "750">
                                    <div slot="content">
                                        (不含税)手续费
                                    </div>
                                    <el-form-item label="手续费:">
                                        <vxe-input style="width: 60px;" :controls="false" :precision="2"
                                                   size="mini" clearable
                                                   type="float" ref="procedure_fees" v-model="formData.procedure_fees"
                                                   @change="formData.procedure_fees_diy = false,isDataChanage = true,$_sumAllFees(),$_setOtherTaxMoney(),$_getFeesEveryRow(),$_singleCountEventEvery()"
                                                   @keyup.native.enter="$refs.xGrid.setActiveCell(tableDataMain[0],'specification')"></vxe-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                            <el-col :sm="12" :md="3" v-if="formData.bill_type == '外购进仓'">
                                <el-tooltip class="item" effect="dark" placement="top" :open-delay = "750">
                                    <div slot="content">
                                        合计杂费 = 磅费 + 风割费 + 装车费 + 中转费 + 运费 + 手续费
                                    </div>
                                    <el-form-item label="合计杂费:" label-width="90px">
                                        <vxe-input :readonly="true" placeholder="自动计算"
                                                  :value="this.$XEUtils.commafy(Number(formData.all_fees), { digits: 2})"
                                                  size="mini"></vxe-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-tooltip class="item" effect="dark" placement="top" :open-delay = "750">
                                    <div slot="content">
                                        合计产品金额  = (每行)产品金额
                                    </div>
                                    <el-form-item label="产品金额:" label-width="80px">
                                        <vxe-input :readonly="true" placeholder="自动计算"
                                                  :value="this.$XEUtils.commafy(Number(formData.total_amount_of_product), { digits: 2})"
                                                  size="mini"></vxe-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-tooltip class="item" effect="dark" placement="top" :open-delay = "750">
                                    <div slot="content">
                                        合计进仓成本 = 每行产品裸价 + 每行外省运费裸价
                                    </div>
                                    <el-form-item label="合计成本:">
                                        <vxe-input :readonly="true" placeholder="自动计算"
                                                  :value="this.$XEUtils.commafy(Number(formData.naked_into_warehouse_cost), { digits: 2})"
                                                  size="mini"></vxe-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="合计数量:">
                                    <vxe-input :readonly="true" placeholder="自动计算" v-model="formData.total_number"
                                              size="mini"></vxe-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="总理论重:">
                                    <vxe-input :readonly="true" placeholder="自动计算"
                                              v-model="formData.total_theoretical_weight" size="mini"></vxe-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-tooltip class="item" effect="dark" placement="top" :open-delay = "750">
                                    <div slot="content">
                                        合计金额  = (每行)产品金额 + (含税)外省运费
                                    </div>
                                <el-form-item label="合计金额:">
                                    <vxe-input :readonly="true" placeholder="自动计算" v-model="formData.total_amount"
                                              size="mini"></vxe-input>
                                </el-form-item>
                                </el-tooltip>
                            </el-col>

                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="总过磅重:">
                                    <vxe-input :readonly="true" placeholder="自动计算" v-model="formData.total_weighing_heavy"
                                              size="mini"></vxe-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-tooltip class="item" effect="dark" placement="top" :open-delay = "750">
                                    <div slot="content">
                                        合计税金 = 外省运费税金 + 产品税金（合计金额-合计裸价）<br/>数据由后台计算，以保存后的数据为准
                                    </div>
                                    <el-form-item label="合计税金:">
                                        <vxe-input :readonly="true" placeholder="自动计算"
                                                  :value="this.$XEUtils.commafy(Number(formData.taxes_fees), { digits: 2})"
                                                  size="mini"></vxe-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-tooltip class="item" effect="dark" placement="top" :open-delay = "750">
                                    <div slot="content">
                                        财务：商品成本，每行产品裸价合计<br/>合计裸价 = 合计金额 ÷ (1 + 产品税率)
                                    </div>
                                    <el-form-item label="合计裸价:">
                                        <vxe-input :readonly="true" placeholder="自动计算"
                                                  :value="this.$XEUtils.commafy(Number(formData.naked_price_total), { digits: 2})"
                                                  size="mini"></vxe-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                            <!-- <el-col :lg="4" :sm="12" :md="8">
                                 <el-form-item label="合计产品金额:" label-width="120px">
                                     <el-input :readonly="true" placeholder="自动计算" v-model="formData.total_amount_of_product"
                                               size="mini"></el-input>
                                 </el-form-item>
                             </el-col>-->

                            <el-col :lg="6" :sm="12" :md="6">
                                <vxe-toolbar class="pull-left" export import custom ref="xToolbar2">
                                </vxe-toolbar>
                               <!-- <el-button type="primary" size="mini" @click="$router.back()"
                                           style="margin-bottom: 2px;margin-left: 12px;margin-top: 12px">返回
                                </el-button>-->
                                <el-button style=" margin-top: 12px; margin-left: 12px;" type="success"
                                           round @click="$router.push('/intoTheWarehouse')" size="mini">新建
                                </el-button>
                                <el-button style=" margin-top: 8px; " type="primary" round
                                           @click="printIntoTheWarehouse()" size="mini">打印
                                </el-button>
                                <el-button style=" margin-top: 12px;  " type="danger" plain
                                           :disabled="this.loading || this.formData.is_cancel"
                                           round @click="onSubmit" size="mini">保存
                                </el-button>
                            </el-col>

                        </el-row>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :lg="24">
                        <MnGrid ref="xGrid" :height="xGridHeight" :table-column="tableColumn" :table-data-main="tableDataMain"
                                v-on:childByTableDataMain="childByTableDataMain" search-type="进仓单"
                                :form-data="formData" :allow-create="false" :search-all="true"></MnGrid>
                    </el-col>
                </el-row>

            </el-form>
        </el-main>
    </div>
</template>

<script>

    import MnGrid from "../../../components/MnGrid";

    export default {
        components: {MnGrid},
        data() {
            return {
                pageType:'进仓单',
                warehouseBackOrGo:{
                    id:'',
                    name:'',
               },//仓库查询 上一张下一张
                filterData: {
                    supplier_name:'',
                    state:"激活"
               },
                titleList:[],
                xGridHeight:"500",
                loading: false,
                //isAddBill: true,
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                   },
                    shortcuts: [{
                        text: '今天',
                        onClick(picker) {
                            picker.$emit('pick', new Date());
                       }
                   }, {
                        text: '昨天',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', date);
                       }
                   }, {
                        text: '一周前',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', date);
                       }
                   }]
               },
                tableColumn: [
                    {type: 'seq',fixed: 'left', title: '序号', className: 'unEditor', width: 40},
                    {
                        field:'specification',
                        fixed: 'left',
                        width: 150, title: '实际规格',
                        slots: {edit: 'specification_pulldown_edit'},
                        editRender: {autofocus: 'input.vxe-input--inner'}
                   },
                    {   field:'full_thick',
                        width: 50,
                        title: '足厚',
                        fixed: 'left',
                        slots: {edit: 'full_thick_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                   },
                    {
                        field:'warehouse',
                        width: 65,
                        title: '调入仓库',
                        fixed: 'left',
                        slots: {edit: 'warehouse_in_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                   },
                    {
                        field:'category',
                        width: 50,
                        title: '类别',
                        slots: {edit: 'category_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                   },

                    {
                        field:'unit', width: 45, title: '单位', slots: {edit: 'unit_edit'},
                        editRender: {
                            autofocus: 'input.el-input__inner',
                       }
                   },
                   {
                        field:'single_count', width: 55, title: '支数',
                        slots: {edit: 'single_count_autocomplete_edit'},
                        editRender: {autofocus: 'input.vxe-input--inner'}
                   },
                    {
                        field:'price', width: 60, title: '单价',
                        titleHelp: {message: '含税\n单位(吨) 单价 = 吨价\n单位(支) 单价 = 每支单价'},
                        slots: {edit: 'price_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                   },
                    {
                        field:'weighing_heavy', width: 74, title: '过磅重(吨)',
                        titleHelp: {message: '过磅重=理论支重*支数\n可以自行修改\n(吨)'},
                        slots: {edit: 'weighing_heavy_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                   },
                    {
                        field:'is_order', width: 50, title: '订货', slots: {edit: 'is_order_edit'},
                        editRender: {
                            autofocus: 'input.el-input__inner',
                       }
                   },
                    {
                        field:'remark_stock',
                        width: 115,
                        title: '产品备注',
                        editRender: {name: 'input', props: {controls: false}}
                   },
                    {
                        field:'amount_of_product',
                        width: 90,
                        title: '产品金额',
                        slots: {edit: 'amount_of_product_edit'},
                        editRender: {autofocus: 'input.el-input__inner'},
                        titleHelp: {message: '含税\n单位(吨) 产品金额= 过磅重*吨价\n单位(支) 产品金额= 支数*每支单价'}
                   },
                    {
                        field:'naked_into_warehouse_cost',
                        width: 65, titleHelp: {message: '(不含税)\n(当前行)进仓成本 = (当前行)产品裸价 + (当前行)外省运费裸价'},
                        title: '成本', className: 'unEditor', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                        }
                    },

                    /*
                    , formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       },

                    {
                        field:'single_count', title: '支数',
                        editRender: {
                            name: 'ElInputNumber', props: {controls: false},
                            events: {change: this.singleCountEvent}
                       }
                   },*/
                    {
                        field:'naked_price',
                        width: 70,
                        title: '产品裸价', className: 'unEditor',
                        titleHelp: {message: '产品裸价 = 产品金额 ÷ (1 + 产品税率)'}, formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       }
                   },

                    {
                        field:'tax_tate_stock',
                        title: '产品税率',
                        width: 50,
                        slots: {edit: 'tax_tate_stock_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                    },
                    {
                        field:'taxes_fees', width: 70, className: 'unEditor', title: '产品税金',
                        titleHelp: {message: '产品税金 = 产品裸价 * 税率'}, formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       }
                   },
                    {
                        field:'single_price', width: 65, className: 'unEditor', title: '每支单价',
                        titleHelp: {message: '(含税单价)\n(单位:支) 每支单价=单价\n(单位:吨) 每支单价 = 产品金额 ÷ 支数'}
                   },
                    {
                        field:'tons_of_price', width: 50, className: 'unEditor', title: '吨价',
                        titleHelp: {message: '(含税吨价)\n(单位:支)吨价 = 产品金额 ÷ 过磅重\n(单位:吨)吨价 = 单价'}
                   },{
                        field:'single_price_bj', width: 80, className: 'unEditor', title: '报价单支价',
                        /*titleHelp: {message: '(含税吨价)\n报价单吨价 = （ 产品金额（含税） + 磅费 + 风割费 + 装车费 + 中转费 + 运费 + 外省运费（含税）+ 10元）÷ 支数 '}*/
                        titleHelp: {message: '(不含税吨价)\n报价单支价 = （ 成本 + 磅费 + 风割费 + 装车费 + 中转费 + 运费 + 10元）÷ 支数 '}
                   },{
                        field:'tons_of_price_bj', width: 80, className: 'unEditor', title: '报价单吨价',
                        /*titleHelp: {message: '(含税吨价)\n报价单吨价 = （（ 产品金额（含税） + 磅费 + 风割费 + 装车费 + 中转费 + 运费 + 外省运费（含税））÷ 过磅重 ） + 10元'}*/
                        titleHelp: {message: '(不含税吨价)报价单吨价 = (成本 + 磅费 + 风割费 + 装车费 + 中转费 + 运费 ) ÷ 过磅重 + 10元'}
                   }, {
                        field:'tons_of_price_practical', width: 70, className: 'unEditor', title: '实际吨价',
                        titleHelp: {message: '(不含税吨价)\n实际吨价=产品裸价 ÷ 过磅重(吨)\n实际每吨价格'}
                   },
                    {
                        field:'batch_number', width: 50, className: 'unEditor', title: '批号',
                        titleHelp: {message: '自动生成'}
                   },
                   {
                        field:'pounds_fees',
                        width: 60,
                        slots: {edit: 'pounds_fees_edit'},
                        titleHelp: {message: '(当前行)磅费 = 合计磅费 ÷ 总过磅重 * (当前行)过磅重\n四舍五入保留两位小数，差值自动调整(加减)到第一个商品\n例如\n总金额：100元\n有4个规格根据重量平均下去：\n20.55元\n36.99元\n19.99元\n22.49元\n因为四舍五入，四个数加起来：100.02元多了2分，则自动拿第一个规格22.49元减去2分钱。'},
                        title: '磅费',
                        editRender: {autofocus: 'input.vxe-input--inner'},
                   }, {
                        field:'wind_cut_fees',
                        width: 60, titleHelp: {message: '(当前行)风割费 = 合计风割费 ÷ 总过磅重 * (当前行)风割费\n四舍五入保留两位小数，差值自动调整(加减)到第一个商品'},
                        slots: {edit: 'wind_cut_fees_edit'},
                        title: '风割费', editRender: {autofocus: 'input.el-input__inner'}
                   }, {
                        field:'loading_car_fees',
                        width: 60, titleHelp: {message: '(当前行)装车费 = 合计装车费 ÷ 总过磅重 * (当前行)过磅重\n四舍五入保留两位小数，差值自动调整(加减)到第一个商品'},
                        slots: {edit: 'loading_car_fees_edit'},
                        title: '装车费', editRender: {autofocus: 'input.el-input__inner'}
                   }, {
                        field:'transfer_fees', title: '中转费',
                        width: 60, titleHelp: {message: '(当前行)中转费 = 合计中转费 ÷ 总过磅重 * (当前行)过磅重\n四舍五入保留两位小数，差值自动调整(加减)到第一个商品'},
                        slots: {edit: 'transfer_fees_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                   }, {
                        field:'freight_fees',
                        width: 60, titleHelp: {message: '(当前行)运费 = 合计运费 ÷ 总过磅重 * (当前行)过磅重\n四舍五入保留两位小数，差值自动调整(加减)到第一个商品'},
                        slots: {edit: 'freight_fees_edit'},
                        title: '运费', editRender: {autofocus: 'input.el-input__inner'}
                   }, {
                        field:'procedure_fees',
                        width: 60, titleHelp: {message: '(当前行)运费 = 合计手续费 ÷ 总过磅重 * (当前行)过磅重\n四舍五入保留两位小数，差值自动调整(加减)到第一个商品'},
                        slots: {edit: 'procedure_fees_edit'},
                        title: '手续费', editRender: {autofocus: 'input.el-input__inner'}
                    }, {
                        field:'other_freight_fees',
                        width: 65, titleHelp: {message: '(含税)\n(当前行)外省运费 = 合计运费 ÷ 总过磅重 * (当前行)过磅重\n四舍五入保留两位小数，差值自动调整(加减)到第一个商品'},
                        slots: {edit: 'other_freight_fees_edit'},
                        title: '外省运费', editRender: {autofocus: 'input.el-input__inner'}
                   },{
                        field:'naked_other_freight_fees_tax_money',
                        width: 90, titleHelp: {message: '(含税)\n(当前行)外省运费裸价 = (当前行)外省运费 ÷ (1 + 外省运费税率)\n四舍五入保留两位小数，差值自动调整(加减)到第一个商品'},
                        title: '外省运费裸价', className: 'unEditor',
                   },{
                        field:'other_freight_fees_tax_money',
                        width: 90, titleHelp: {message: '(含税)\n(当前行)外省运费税金 = (当前行)外省运费 - (当前行)外省运费裸价\n四舍五入保留两位小数，差值自动调整(加减)到第一个商品'},
                        title: '外省运费税金', className: 'unEditor',
                   },

                    {
                        field:'the_theory_of_count',
                        width: 65,
                        className: 'unEditor',
                        titleHelp: {message: '理论支数=过磅重 ÷ 每支理论重量'},
                        title: '理论支数'
                   },
                    {
                        field:'single_weight',
                        width: 60,
                        className: 'unEditor',
                        titleHelp: {message: '支重=过磅重/支数\n(kg)'},
                        title: '支重'
                   },
                    {
                        field:'theoretical_weight',
                        width: 90, className: 'unEditor',
                        titleHelp: {message: '理论重量 = 理论支重 * 支数\n(吨)'},
                        title: '理论重量'
                   },
                    {
                        field:'theoretical_weight_single', width: 65, className: 'unEditor',
                        titleHelp: {message: '理论支重=[(边长a+边长b)*2-4*壁厚]*壁厚*0.00785*长度\n(吨)'},
                        title: '理论支重'
                   }, {
                        field:'warehouse_id', visible: false,
                        width: 65,
                        className: 'unEditor',
                        title: '调入仓库Id'
                   },{
                        field:'practical_price',
                        width: 70,
                        title: '实收金额',
                        className: 'unEditor',
                        titleHelp: {message: '实收金额=实收单价 * 支数'},visible: false,
                   },{
                        field:'kick_back',
                        width: 50,
                        title: '佣金',
                        className: 'unEditor',
                        titleHelp: {message: '佣金=产品金额 - 实收金额'},visible: false,
                   }, {
                        field:'single_price_practical', width: 80, title: '实收单价',
                        className: 'unEditor',visible: false,
                   },
                    {field:'operate',title: '操作',fixed:'right' , width: 35, slots: {default: 'operate'}}
                ],
                tableDataMain: [],
                accountList: [],
                formData: {
                    company_uuid: '',
                    only_bill_date: '',
                    bill_id_type: 'JC',
                    total_amount_of_product : '',//进仓单 合计产品金额
                    creat_date_warehouse_bill : '',
                    bill_id: '',
                    wb_id: '',
                    handled_by: '',
                    registrar: '',
                    last_date_warehouse_bill: '',
                    last_name_warehouse_bill: '',
                    tax_tate_warehouse_bill : '',
                    supplier: '',
                    cost_of_the_unit : '',
                    remark_warehouse_bill: '',
                    pounds_fees : '',//磅费
                    wind_cut_fees : '',//风割费
                    loading_car_fees : '',//装车费
                    transfer_fees : '',//中转费
                    freight_fees : '',//运费
                    procedure_fees : '',//手续费
                    taxes_fees : '',//税金
                    other_freight_fees : '',//外省运费
                    other_freight_fees_tax_money : '',//外省运费税金
                    other_freight_fees_tax: '',//外省运费税率
                    naked_other_freight_fees_tax_money : '',//(不含税)外省运费裸价
                    pounds_fees_diy: false,//磅费Diy
                    wind_cut_fees_diy: false,//风割费Diy
                    loading_car_fees_diy: false,//装车费Diy
                    transfer_fees_diy: false,//中转费Diy
                    freight_fees_diy: false,//运费Diy
                    procedure_fees_diy: false,//手续费Diy
                    other_freight_fees_diy: false,//外省运费Diy
                    is_contract_fees: 0,//是包费用 进仓单不需要合计杂费，所以就等于包费用
                    total_number: '',
                    total_theoretical_weight: '',
                    total_amount: '',
                    total_weighing_heavy : '',
                    naked_into_warehouse_cost : '',//进仓单合计成本
                    bill_type: '外购进仓',
                    is_cancel: false,
               },
                unitList: [],
                categoryList: [],
                specificationList: [],
                activeName: 'first',
                firstOpen: true,
                supplierList: [],
                billTypeList: [],
           }
       },
        methods: {

            //查询账户
            searchAccountList(query) {
                this.$axios({
                    method: 'post',
                    url: '/admin/account/getAccountList',
                    params: {
                        keyword: query
                   }
               }).then((response) => {          //这里使用了ES6的语法
                    //(response)       //请求成功返回的数据
                    //console.log(response)
                    if (response.status == 200 && response.data.state == "ok") {
                        this.accountList = response.data.page.list
                        //console.log(response)
                   } else {
                        return
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
            selectBillType() {
                //console.log("selectBillType");
                if (this.formData.bill_type != '外购进仓') {
                    this.formData.supplier = '';//供应商
                    this.formData.cost_of_the_unit = '';//外省运费单位
                    this.formData.other_freight_fees = 0;//外省运费
                    this.formData.other_freight_fees_tax = 0;//外省运费税率
                    this.formData.naked_other_freight_fees_tax_money = 0;//外省运费裸价
                    this.formData.other_freight_fees_tax_money = 0;//外省运费税金
                    this.formData.pounds_fees = 0;//磅费
                    this.formData.wind_cut_fees = 0;//风割费
                    this.formData.loading_car_fees = 0;//装车费
                    this.formData.transfer_fees = 0;//中转费
                    this.formData.freight_fees = 0;//运费
                    this.formData.procedure_fees = 0;//合计手续费
                    this.formData.all_fees = 0;//合计杂费
                    this.isDataChanage = true;
                    this.$_sumAllFees();
                    this.$_setOtherTaxMoney();
                    this.$_getFeesEveryRow();
                    this.$_singleCountEventEvery()
                    this.xGridHeight = (this.getViewHeight() - 230)+'';
                    this.$refs.xGrid.isEditFees = false
                    for (var t in this.tableDataMain) {
                        this.tableDataMain[t].pounds_fees ='';
                        this.tableDataMain[t].wind_cut_fees ='';
                        this.tableDataMain[t].loading_car_fees ='';
                        this.tableDataMain[t].transfer_fees ='';
                        this.tableDataMain[t].freight_fees ='';
                        this.tableDataMain[t].other_freight_fees ='';
                        this.tableDataMain[t].procedure_fees ='';
                   }
               }else{
                    this.xGridHeight = (this.getViewHeight() - 270)+'';
                    this.$refs.xGrid.isEditFees = true
               }
           },
            //设置大写
            upper({row}) {
                if (!row.texture) return '';
                row.texture = row.texture.toString().toUpperCase();
           },

            //查询进仓单类型
            async searchBillTypeList(query) {
                this.$axios({
                    method: 'post',
                    url: '/admin/warehouseEntryNote',
                    params: {
                        keyword: query
                   }
               }).then((response) => {          //这里使用了ES6的语法
                    //(response)       //请求成功返回的数据
                    //console.log(response)
                    if (response.status == 200) {
                        this.billTypeList = response.data.page.list
                        if (this.firstOpen && this.formData.bill_id == null) {
                            this.formData.bill_type = this.billTypeList[0].name;
                            this.selectBillType();
                            this.firstOpen = false;
                       }

                        //console.log(response)
                   } else {
                        return
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },

            searchWarehouseBill() {
                this.loading = true;
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据处理中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                this.$axios({
                    method: 'post',
                    url: '/admin/warehouseEntryBill/search',
                    params: {
                        bill_id: this.formData.bill_id,
                        wb_id: this.formData.wb_id,
                   },
               }).then((response) => {          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    this.loading = false;
                    loading.close();
                    this.titleList = [];
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.showCustomerEdit = false;
                        //this.isAddBill = false;
                        this.formData = response.data.warehouseBill;
                        this.tableDataMain = this.$_createRowData(response.data.stocks);
                        this.formData.is_contract_fees = 0;
                        //判断是否开了收款单
                        if (this.formData.total_amount == this.formData.balance) {
                            this.loading = false;
                            //不为空，说明是：已审核
                            if (!this.isBlankVue(this.formData.cn_state_man)) {
                                this.loading = true;
                                this.titleList.push('出纳已审核');
                                //this.$XModal.alert({content: '出纳已审核，不能修改！请联系出纳处理！', maskClosable: true})
                           }
                       } else {
                            //this.loading = true;
                            this.titleList.push('已开《付款单》');
                            //this.$XModal.alert({content: '已开“付款单”不能修改，请联系出纳取消“付款单”的金额！', maskClosable: true})
                       }
                        this.selectBillType();
                   } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) => {
                    loading.close();
                    console.log(error)
               });
           },
            printIntoTheWarehouse() {
                const{ href} = this.$router.resolve({path: '/printIntoTheWarehouse', query: {bill_id: this.formData.bill_id,wb_id: this.formData.id}});
                window.open(href,'_blank');
                //this.$router.push({path: '/printIntoTheWarehouse', query: {bill_id: row.bill_id,wb_id: row.b_id}})
           },
            async onSubmit() {
                this.loading = true;
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据处理中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                let url = '/admin/warehouseEntryBill/saveOrUpdateEntryBill';
                await this.$axios({
                    method: 'post',
                    url: url,
                    data: {
                        formData: this.formData,
                        /*tableDataMain: this.tableDataMain,*/
                        tableDataMain: this.$refs.xGrid.$refs.baseGird.getTableData().fullData,
                   },
               }).then((response) => {          //这里使用了ES6的语法
                    loading.close();
                    this.loading = false;
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.$message({
                            showClose: true,
                            message: '保存成功',
                            type: 'success'
                       });
                        this.$router.push({
                            path: "/intoTheWarehouse",
                            query: {bill_id: response.data.warehouseBill.bill_id}
                       })
                        if(!this.isBlankVue(this.formData.bill_id)){
                            this.searchWarehouseBill();
                       }

                   } else {
                        this.loading = false;
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) => {
                    this.loading = false;
                    console.log(error)
                    loading.close();
                    this.$message({
                        showClose: true,
                        message: '网络超时，请重试！',
                        type: 'error'
                   });
               });
           },
            //子传父，子修改父
            childByTableDataMain: function (childValue) {
                // childValue就是子组件传过来的值
                this.tableDataMain = childValue;
                this.$_singleCountEventEvery();
                this.$_singleCountEventEvery();
           },
       },
        created() {
            this.xGridHeight = (this.getViewHeight() - 180)+'';
            //console.log(this.billTypeList)
            this.formData.only_bill_date = new Date();
            this.$_searchSupplierList('');
            this.$_searcWarehouseList('');
            this.$_searchCategoryList('');
            this.$_searchUnitList('');
            //this.searchAccountList('');
            this.$_getAccountListByRoleId('16');//经手人
            //默认选择第一个
            this.formData.bill_id = this.$route.query.bill_id;
            this.formData.wb_id = this.$route.query.wb_id
            if (this.$route.query.BackOrGoId != null && this.$route.query.BackOrGoId != '') {
                this.warehouseBackOrGo.id = this.$route.query.BackOrGoId
                this.warehouseBackOrGo.name = this.$route.query.BackOrGoName
           }
            this.searchBillTypeList('');
            if (this.formData.bill_id == null || this.formData.bill_id == '') {
                this.formData.registrar = this.$store.getters.getLoginAccount.nick_name;
           }
            if (this.formData.bill_id == null || this.formData.bill_id == '') {
                var list = [];
                this.tableDataMain = this.$_createRowData(list);
           } else {
                this.searchWarehouseBill();
           }
            this.$nextTick(() => {

                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2)

           })
       }
   };


</script>

<style scoped>
    /*    .input-with-select .el-input .el-input--mini .el-input-group .el-input-group--prepend .el-input-group__prepend{
            width: 80px !important;
       }*/
    .vxe-table--render-default .vxe-cell {
        padding-left: initial !important;
        padding-right: initial !important;
   }

    /*    .el-date-editor.el-input, .el-date-editor.el-input__inner{
            width: 128px !important;
       }*/
    /*    .el-form-item__content .el-input-group{
            vertical-align: initial;
       }*/
    /deep/ .vxe-input--number-suffix {
        display: none;
   }

    /deep/ .vxe-input {
        width: 100%;
   }

    /deep/ .vxe-input--suffix {
        height: auto;
   }

</style>
